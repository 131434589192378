import {configureStore} from '@reduxjs/toolkit';
import homeReducer from 'pages/Home/homeSlice';
import authReducer from 'pages/Auth/authSlice';
import productReducer from 'pages/Product/productSlice';
import voucherReducer from 'pages/Voucher/voucherSlice';
import loaderReducer from './loaderSlice';
import orderReducer from 'pages/Order/orderSlice';
import dashboardReducer from 'pages/Dashboard/dashboardSlice';

const rootReducer = {
    home: homeReducer,
    auth: authReducer,
    loader: loaderReducer,
    products: productReducer,
    voucher: voucherReducer,
    order: orderReducer,
    dashboard: dashboardReducer
};

const store = configureStore({
    reducer: rootReducer
});

export default store;
