import {FormControl} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomInput from 'components/CustomInput/CustomInput';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import navigateTo from 'components/NavigateTo';
import {Select} from 'antd';
import {pagePath} from 'configuration/routeConfig';

const OrderUpdate = (props) => {
    const {
        dispatch,
        history,
        handleSubmitOrder,
        handleSubmitInvalid,
        productList,
        voucherList,
        setInputFieldProduct,
        inputFieldProduct,
        handleAddFieldsProduct,
        handleRemoveFieldsProduct,
        handleChangeInput
    } = props;

    const {selectedOrder = {}, isEdit} = history.location.state || {};

    useEffect(() => {
        setInputFieldProduct([
            ...selectedOrder?.order_items.map((item) => ({
                product_id: item.product?.name,
                product_code: item.product?.id,
                count: item.count,
                total: item?.total * item?.count,
                options: productList.map((item) => ({value: item.id, label: item.name}))
            }))
        ]);
    }, []);
    const defaultValues = {
        id: selectedOrder?.id,
        user_name: selectedOrder?.user?.username,
        shipping_fee: selectedOrder?.shipping_fee,
        status: selectedOrder?.status,
        old_status: selectedOrder?.status,
        voucher_id: selectedOrder?.voucher?.code,
        old_voucher_id: selectedOrder?.voucher?.id,
        discount_price: selectedOrder?.discount_price
    };

    const orderInputs = [
        {
            label: 'Tên khách hàng',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'user_name'
        },
        {
            label: 'Phí Giao Hàng',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'shipping_fee'
        },
        {
            label: 'Trạng thái đơn',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'dropDown',
            name: 'status',
            options: [
                {
                    value: 'PROCESSING',
                    label: 'Đang xử lý'
                },
                {
                    value: 'DELIVERING',
                    label: 'Đang giao'
                },
                {
                    value: 'DELIVERED',
                    label: 'Đã giao hàng'
                },
                {
                    value: 'CANCELED',
                    label: 'Đã huỷ'
                }
            ]
        },
        {
            label: 'Mã giảm giá',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'dropDown',
            name: 'voucher_id',
            options: [
                {
                    value: null,
                    label: 'Không dùng mã giảm giá'
                },
                ...voucherList.map((item) => ({value: item.id, label: item.code}))
            ]
        }
    ];

    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});

    const watchAllFields = watch();
    const isFormValid = Object.values(watchAllFields).every((value) => {
        if (value && typeof value === 'object') {
            return new Date(value).toISOString().length > 0;
        }
        return value?.length > 0;
    });

    return (
        <CustomCard title='Thông tin đơn hàng'>
            <form
                className='mt-2 add-program'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleSubmitOrder, handleSubmitInvalid)}>
                {orderInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, options, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            <Controller
                                name={name}
                                control={control}
                                rules={rules}
                                key={idx}
                                render={({field}) =>
                                    type === 'dropDown' ? (
                                        <div>
                                            <span>{label}</span>
                                            <Select
                                                name={name}
                                                value={field.value}
                                                defaultValue={'Chọn mã giảm giá'}
                                                style={{
                                                    width: '100%',
                                                    height: '50%'
                                                }}
                                                onChange={field.onChange}
                                                options={options}
                                            />
                                        </div>
                                    ) : (
                                        <CustomInput
                                            label={label}
                                            {...field}
                                            {...restProps}
                                            type={type}
                                            disabled={name === 'user_name'}
                                        />
                                    )
                                }
                            />
                        </FormControl>
                    );
                })}

                {inputFieldProduct?.map((inputField, idx) => {
                    return (
                        <CustomCard
                            title={`Sản phẩm ${inputFieldProduct?.length > 1 ? `thứ ${idx + 1}` : ''}`}
                            isDelete={true}
                            onClick={() => handleRemoveFieldsProduct(idx)}>
                            <div className='mt-3 add-program' style={{width: '100%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                                    <span>Sản phẩm</span>
                                    <Select
                                        name={'product_id'}
                                        value={inputField.value}
                                        defaultValue={inputField.product_id || 'Chọn sản phẩm'}
                                        onChange={(e) => handleChangeInput(e, idx)}
                                        options={inputField.options}
                                        style={{width: '100%'}}
                                    />
                                </div>
                                <CustomInput
                                    label={'Số lượng'}
                                    name={'count'}
                                    defaultValue={inputField.count}
                                    type={'number'}
                                    onChange={(event) => handleChangeInput(event, idx)}
                                />
                            </div>
                        </CustomCard>
                    );
                })}

                <div className='w-100 d-flex align-items-center form-buttons'>
                    <div>
                        <CustomButton
                            style={{width: '110%'}}
                            variant='contained'
                            onClick={() => handleAddFieldsProduct()}
                            type='button'>
                            <span>Thêm sản phẩm</span>
                        </CustomButton>
                    </div>
                    <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                        <CustomButton
                            variant='outlined'
                            onClick={() => navigateTo(history, dispatch, pagePath.orderUrl)}
                            type='button'>
                            Trở lại
                        </CustomButton>
                        <CustomButton variant='contained' type='submit'>
                            Lưu
                        </CustomButton>
                    </div>
                </div>
            </form>
        </CustomCard>
    );
};

export default OrderUpdate;
