const environmentConfig = {
    development: {
        endPointAuth: 'https://gateway.dev.meu-solutions.com/meu-shop-user-be/api/v1.0',
        endPointOrder: 'https://gateway.dev.meu-solutions.com/meu-shop-ord-be/api/v1.0',
        endPoint: 'https://gateway.dev.meu-solutions.com/meu-shop-pr-be/api/v1.0'
    },
    production: {
        endPointAuth: 'https://gateway.dev.meu-solutions.com/meu-shop-user-be/api/v1.0',
        endPointOrder: 'https://gateway.dev.meu-solutions.com/meu-shop-ord-be/api/v1.0',
        endPoint: 'https://gateway.dev.meu-solutions.com/meu-shop-pr-be/api/v1.0'
    },
    empty: {
        endPointAuth: 'https://gateway.dev.meu-solutions.com/meu-shop-user-be/api/v1.0',
        endPointOrder: 'https://gateway.dev.meu-solutions.com/meu-shop-ord-be/api/v1.0',
        endPoint: 'https://gateway.dev.meu-solutions.com/meu-shop-pr-be/api/v1.0'
    }
};
export default environmentConfig;
