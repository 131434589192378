export default function propsProvider(props) {
    const {chartSetting, valueFormatter, valueFormatterIncome, datasetOrder, datasetIncome, sizeWindow} = props;

    return {
        ...props,
        chartSetting,
        valueFormatter,
        valueFormatterIncome,
        datasetOrder,
        datasetIncome,
        sizeWindow
    };
}
