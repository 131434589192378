import ModalPopup from 'components/ModalPopup/ModalPopup';
import Home from 'pages/Home';
import OrderList from './subViews/OrderList';
import OrderDetail from './subViews/OrderDetail';
import OrderUpdate from './subViews/OrderUpdate';

const OrderMainView = (props) => {
    const {modalContent, OrderDetailProps, history} = props;
    let mainComponent = null;
    switch (history.location.pathname) {
        case '/order':
            mainComponent = <OrderList {...props} />;
            break;
        case '/order/detail':
            mainComponent = <OrderDetail {...OrderDetailProps} />;
            break;
        case '/order/update':
            mainComponent = <OrderUpdate {...OrderDetailProps} />;
            break;
        default:
            mainComponent = <OrderList {...props} />;
            break;
    }
    return (
        <Home>
            <ModalPopup {...modalContent} />
            {mainComponent}
        </Home>
    );
};

export default OrderMainView;
