import {Trash} from 'assets';
import React from 'react';

const PreviewImage = (props) => {
    const {image, onDeleteImage, onClick} = props;
    return (
        <div className='preview-image'>
            <img src={image} alt='' onClick={onClick} style={{cursor: 'pointer'}} />
            {onDeleteImage && (
                <div className='preview-image__wrapper'>
                    <Trash onClick={onDeleteImage} />
                </div>
            )}
        </div>
    );
};

export default PreviewImage;
