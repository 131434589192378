import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {productsSelector} from 'app/selectors';
import {useHistory} from 'react-router-dom';
import ProductContainer from './productContainer';

const Product = (props) => {
    const dispatch = useDispatch();
    const products = useSelector(productsSelector);
    const history = useHistory();
    const productContainerProps = {
        ...props,
        dispatch,
        history,
        products,
    };

    return <ProductContainer {...productContainerProps} />;
};

export default Product;
