import axiosFormData from './axiosFormData';

const fileApi = {
    uploadFile: (file) => {
        const url = `/file/upload`;
        return axiosFormData.post(url, file);
    },
    deleteFile: (id) => {
        const url = `/file/delete/${id}`;
        return axiosFormData.delete(url);
    }
};
export default fileApi;
