import {useEffect, useState} from 'react';

export const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
};

export function useDeviceSize() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize, {passive: true});
        return () => window.removeEventListener('resize', handleResize, {passive: true});
    }, [windowDimensions]);

    return [windowDimensions, setWindowDimensions];
}
