import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {CheckOutlined, CloseOutlined} from '@mui/icons-material';
import GroupInput from 'components/GroupInput/GroupInput';
import navigateTo from 'components/NavigateTo';
import {Select} from 'antd';
import dayjs from 'dayjs';
import VNSubOrderStatus from 'utils/vnSubOrderStatus';
import PreviewImage from 'components/PreviewImage/PreviewImage';

const OrderDetail = (props) => {
    const {dispatch, history, handleSubmitOrder, handleSubmitInvalid, voucherList} = props;
    const {selectedOrder = {}, isEdit} = history.location.state || {};
    
    const defaultValues = {
        id: selectedOrder?.id,
        user: selectedOrder?.user?.username,
        total_price: selectedOrder?.total_price,
        shipping_fee: selectedOrder?.shipping_fee,
        discount_price: selectedOrder?.discount_price,
        status: VNSubOrderStatus(selectedOrder?.status),
        created_at: dayjs(selectedOrder?.created_at).format('HH:mm DD/MM/YYYY'),
        voucher_id: selectedOrder?.voucher?.code,
        old_voucher_id: selectedOrder?.voucher?.id
    };

    const orderInputs = [
        {
            label: 'Tên khách hàng',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'user'
        },
        {
            label: 'Tổng Giá',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'total_price'
        },
        {
            label: 'Phí Giao Hàng',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'shipping_fee'
        },
        {
            label: 'Ngày lên đơn',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'date',
            name: 'created_at'
        },
        {
            label: 'Mã giảm giá',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'dropDown',
            name: 'voucher_id',
            options: [
                {
                    value: '',
                    label: 'Không dùng mã giảm giá'
                },
                ...voucherList.map((item) => ({value: item.id, label: item.code}))
            ]
        },
        {
            label: 'Trạng thái đơn',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'dropDown',
            name: 'status',
            options: [
                {
                    value: 'PROCESSING',
                    label: 'Đang xử lý'
                },
                {
                    value: 'DELIVERING',
                    label: 'Đang giao'
                },
                {
                    value: 'DELIVERED',
                    label: 'Đã giao hàng'
                },
                {
                    value: 'CANCELED',
                    label: 'Đã huỷ'
                }
            ]
        }
    ];

    const orderInputsProduct = [
        {
            label: 'Tên sản phẩm',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'name'
        },
        {
            label: 'Số lượng',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'count'
        },
        {
            label: 'Tổng tiền',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'total'
        },
        {
            label: 'Giá gốc',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'original_price'
        },
        {
            label: 'Giá giảm',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'promotion_price'
        }
    ];

    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});

    const watchAllFields = watch();
    const isFormValid = Object.values(watchAllFields).every((value) => {
        if (value && typeof value === 'object') {
            return new Date(value).toISOString().length > 0;
        }
        return value?.length > 0;
    });

    return (
        <CustomCard title='Thông tin đơn hàng'>
            <form
                className='mt-2 add-program'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleSubmitOrder, handleSubmitInvalid)}>
                {orderInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, options, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            {type !== 'group' ? (
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={rules}
                                    key={idx}
                                    render={({field}) =>
                                        type === 'date' ? (
                                            <CustomDatePicker
                                                {...field}
                                                {...restProps}
                                                label={label}
                                                showTime={false}
                                                disabled={true}
                                            />
                                        ) : type === 'dropDown' ? (
                                            <div>
                                                <span>{label}</span>
                                                <Select
                                                    name={name}
                                                    value={field.value}
                                                    defaultValue={'Chọn'}
                                                    style={{
                                                        width: '100%',
                                                        height: '60%'
                                                    }}
                                                    onChange={field.onChange}
                                                    options={options}
                                                    disabled={true}
                                                />
                                            </div>
                                        ) : (
                                            <CustomInput
                                                label={label}
                                                {...field}
                                                {...restProps}
                                                type={type}
                                                disabled={true}
                                            />
                                        )
                                    }
                                />
                            ) : (
                                <GroupInput
                                    control={control}
                                    label={label}
                                    key={idx}
                                    groupInputData={groupInputData}
                                    disabled={true}
                                    showTime={false}
                                />
                            )}
                        </FormControl>
                    );
                })}
                <div className='' />
            </form>

            {selectedOrder.order_items.map((orderItem, idx) => {
                return (
                    <CustomCard
                        title={`Sản phẩm ${selectedOrder.order_items.length > 1 ? `thứ ${idx + 1}` : ''}`}
                        key={idx}>
                        <div className='mt-2 add-program'>
                            {orderInputsProduct.map((input, idx) => {
                                const {label, name} = input;
                                const value =
                                    name === 'voucher_code'
                                        ? orderItem?.voucher?.code
                                        : name === 'count' || name === 'total'
                                        ? orderItem?.[name]
                                        : orderItem?.product?.[name] === -1
                                        ? '0'
                                        : orderItem?.product?.[name];

                                return <CustomInput label={label} value={value} disabled={true} />;
                            })}
                            <div style={{marginBottom: 20, width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                                {orderItem?.product?.images.length > 0 &&
                                    orderItem?.product?.images.map((image, idx) => {
                                        return (
                                            <PreviewImage
                                                key={idx}
                                                image={image?.file_path}
                                                onClick={() => {
                                                    window.open(image?.file_path, '_blank');
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </CustomCard>
                );
            })}
        </CustomCard>
    );
};

export default OrderDetail;
