import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
export default function propsProvider(props) {
    const {
        searchVoucherValue,
        setSearchVoucherValue,
        handleSearchVoucher,
        dispatch,
        history,
        voucherList,
        setPage,
        activePage,
        vouchers,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        handleSubmitVoucher,
        handleSubmitInvalid,
        handleVoucherDetail,
        handleDeleteVoucher,
        handleAddNewVoucher,
        modalContent
    } = props;
    const {paginationData} = vouchers;
    const {page, pageSize, totalPage} = paginationData[activePage] || {};
    const voucherTableData =
        voucherList &&
        voucherList.map((el) => ({
            id: el?.id,
            code: {value: el?.code, isshow: true},
            sale_percentage: {value: el?.sale_percentage, isshow: true},
            payment_method: {value: el?.payment_method, isshow: true},
            // is_limited: {value: el?.is_enabled ? 'Còn hiệu lực' : 'Hết hiệu lực', isshow: true},
            valid_from: {value: dayjs(el?.valid_from).format('DD/MM/YYYY'), isshow: true},
            valid_to: {value: dayjs(el?.valid_to).format('DD/MM/YYYY'), isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <VisibilityIcon className='cursor-pointer' onClick={() => handleVoucherDetail(el?.id, false)} />
                        <EditIcon className='cursor-pointer' onClick={() => handleVoucherDetail(el?.id, true)} />
                        <CancelIcon className='cursor-pointer' onClick={() => handleDeleteVoucher(el)} />
                    </div>
                ),
                isshow: true
            },
            voucherObj: {
                ...el
            }
        }));
    const voucherHeadCells = [
        {
            id: 'Mã khuyến mãi',
            align: 'left',
            padding: 'none',
            label: 'Mã khuyến mãi'
        },
        {
            id: 'Phần trăm',
            align: 'left',
            padding: 'normal',
            label: 'Phần trăm'
        },
        {
            id: 'Hình thức thanh toán',
            align: 'left',
            padding: 'normal',
            label: 'Hình thức thanh toán'
        },
        {
            id: 'Thời gian hiệu lực từ',
            align: 'left',
            padding: 'normal',
            label: 'Thời gian hiệu lực bắt đầu'
        },
        {
            id: 'Thời gian hiệu lực đến',
            align: 'left',
            padding: 'normal',
            label: 'Thời gian hiệu lực đến'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];

    const addVoucherProps = {
        dispatch,
        history,
        handleSubmitVoucher,
        handleSubmitInvalid,
    };
    return {
        ...props,
        searchVoucherValue,
        setSearchVoucherValue,
        handleSearchVoucher,
        dispatch,
        history,
        headCells: voucherHeadCells,
        tableData: voucherTableData,
        setPage,
        page,
        pageSize,
        totalPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        addVoucherProps,
        handleAddNewVoucher,
        modalContent
    };
}
