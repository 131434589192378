import {BarChart} from '@mui/x-charts/BarChart';
import Home from 'pages/Home';
const DashboardMainView = (props) => {
    const {chartSetting, valueFormatter, valueFormatterIncome, datasetOrder, datasetIncome, sizeWindow} = props;
    return (
        <Home>
            <div style={{padding: '16px', overflow: 'auto', scrollbarWidth: 'auto', height: sizeWindow.height / 1.3}}>
                <BarChart
                 
                    borderRadius={6}
                    dataset={datasetOrder}
                    colors={['#36a46d']}
                    xAxis={[{scaleType: 'band', dataKey: 'day'}]}
                    series={[{dataKey: 'order', label: 'Đơn hàng', valueFormatter}]}
                    {...chartSetting}
                />
                <BarChart
                    borderRadius={6}
                    dataset={datasetIncome}
                    colors={['#36a46d']}
                    xAxis={[{scaleType: 'band', dataKey: 'day'}]}
                    series={[{dataKey: 'order', label: 'Doanh thu', valueFormatterIncome}]}
                    {...chartSetting}
                />
            </div>
        </Home>
    );
};

export default DashboardMainView;
