import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import serviceRequest from 'app/serviceRequest';
import dashboardApi from 'api/dashboardApi';
import Config from 'configuration';
const initialState = () => {
    if (sessionStorage.getItem(Config.storageKey)) {
        return {
            orderDashboard: JSON.parse(sessionStorage.getItem(Config.storageKey.orderDashboard)),
            inComeDashboard: JSON.parse(sessionStorage.getItem(Config.storageKey.inComeDashboard))
        };
    }
    return {
        orderDashboard: [],
        inComeDashboard: []
    };
};

export const getNumberOfOrderByMonth = createAsyncThunk('dashboard/getNumberOfOrderByMonth', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: dashboardApi.getNumberOfOrderByMonth,
        payload: data
    });
});

export const getIncomeByMonth = createAsyncThunk('dashboard/getIncomeByMonth', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: dashboardApi.getIncomeByMonth,
        payload: data
    });
});

const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getNumberOfOrderByMonth.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orderDashboard = action?.payload?.responseData?.data;
            sessionStorage.setItem(Config.storageKey.orderDashboard, JSON.stringify(state));
        });
        builders.addCase(getIncomeByMonth.fulfilled, (state, action) => {
            state.isLoading = false;
            state.inComeDashboard = action?.payload?.responseData?.data;
            sessionStorage.setItem(Config.storageKey.inComeDashboard, JSON.stringify(state));
        });
    }
});

const {reducer, actions} = dashboard;
export default reducer;
