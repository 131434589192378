import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'pages/Auth/authSlice';

const navigateTo = (history, dispatch, path) => {
    try {
        if (path === pagePath.logoutUrl) {
            dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
        } else {
            history.push(path);
        }
    } catch (e) {
        CustomToast(false, 'Có lỗi xảy ra');
    }
};

export default navigateTo;
