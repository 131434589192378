import axiosClientAuth from './axiosClientAuth';

const authApi = {
    authenticate: (data) => {
        const url = '/user/login';
        return axiosClientAuth.post(url, data);
    },
    getMeData: (data) => {
        const url = '/user/getMeData';
        return axiosClientAuth.get(url, data);
    },
    logout: () => {
        const url = '/api/logout';
        return axiosClientAuth.post(url);
    },
    forgotPassword: (data) => {
        const url = '/api/forgot-password';
        return axiosClientAuth.post(url, data);
    },
    resetPassword: (data) => {
        const url = '/api/reset-password';
        return axiosClientAuth.post(url, data);
    },
    deleteAccount: (data) => {
        const url = '/api/deleteAccount';
        return axiosClientAuth.delete(url, {data: data});
    },
    sendOTPDelete: (data) => {
        const url = '/api/send-delete-otp';
        return axiosClientAuth.post(url, data);
    }
};

export default authApi;
