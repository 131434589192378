import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useDeviceSize } from 'utils/hooks/useDeviceSize';

const CustomPagination = ({ totalPage = 0, currentPage = 0, setPage }) => {
    const [windowDimensions, setWindowDimensions] = useDeviceSize();

    const handleChange = (e, value) => {
        setPage(value);
    };

    return (
        <Stack spacing={2}>
            <Pagination
                count={totalPage ?? 1}
                page={currentPage}
                onChange={handleChange}
                shape='rounded'
                showFirstButton
                showLastButton
                size={windowDimensions.width < 576 ? 'small' : 'medium'}
            />
        </Stack>
    );
};

export default CustomPagination;
