import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import InputFiles from 'components/InputFiles/InputFiles';
import {Controller, useForm} from 'react-hook-form';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import navigateTo from 'components/NavigateTo';

const UpdateProduct = (props) => {
    const {
        handleSubmitInvalid,
        handleUpdateProduct,
        dispatch,
        history,
        detailImageList,
        handleDeleteImageList,
        handleUploadImageList,
        handleDeleteImageApi
    } = props;
    const {selectedProduct} = history.location.state || {};
    const defaultValues = {
        id: selectedProduct?.id,
        description: selectedProduct?.description,
        original_price: selectedProduct?.original_price,
        product_count: selectedProduct?.product_count,
        quantity_sold: 99,
        promotion_price: selectedProduct?.promotion_price === -1 ? 0 : selectedProduct?.promotion_price,
        product_main_raw_list: selectedProduct?.product_main_raw_list,
        images: selectedProduct?.images,
        product_name: selectedProduct?.name
    };
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});
    const watchAllFields = watch();
    const isFormValid = Object.values(watchAllFields).every((value) => {
        if (value && typeof value === 'object') {
            return new Date(value).toISOString().length > 0;
        }

        return value?.length > 0;
    });

    const addProductInputs = [
        {
            label: 'Tên sản phẩm',
            placeholder: 'Điền mô tả của sản phẩm',
            isRequired: true,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'product_name'
        },
        {
            label: 'Mô tả sản phẩm',
            placeholder: 'Điền mô tả của sản phẩm',
            isRequired: true,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'description'
        },
        {
            label: 'Sản lượng đã bán',
            placeholder: 'Nhập sản lượng đã bán',
            isRequired: true,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'quantity_sold'
        },
        {
            label: 'Giá gốc',
            placeholder: 'Nhập giá sản phẩm',
            isRequired: true,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'original_price'
        },
        {
            label: 'Giá khuyến mãi',
            placeholder: 'Nhập giá khuyến mãi',
            isRequired: true,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'promotion_price'
        },
        {
            label: 'Danh sách nguyên liệu',
            placeholder: 'Nhập các nguyên liệu tạo cách nhau bởi dấu " , "',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'product_main_raw_list'
        },
        {
            label: 'Số lượng sản phẩm',
            placeholder: 'Nhập số lượng sản phẩm',
            isRequired: true,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'product_count'
        }
    ];
    return (
        <CustomCard title='Thông tin sản phẩm'>
            <form
                className='mt-2 add-program'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleUpdateProduct, handleSubmitInvalid)}>
                {addProductInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            <Controller
                                name={name}
                                control={control}
                                rules={rules}
                                key={idx}
                                render={({field}) =>
                                    type === 'date' ? (
                                        <CustomDatePicker {...field} {...restProps} label={label} showTime={true} />
                                    ) : (
                                        <CustomInput label={label} {...field} {...restProps} type={type} />
                                    )
                                }
                            />
                        </FormControl>
                    );
                })}
                <div style={{marginBottom: 20, width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                    {selectedProduct?.images?.length > 0 &&
                        selectedProduct?.images?.map((image, idx) => {
                            return (
                                <PreviewImage
                                    key={idx}
                                    image={image?.file_path}
                                    onDeleteImage={() => handleDeleteImageApi(image?.id)}
                                />
                            );
                        })}
                    {detailImageList?.length > 0 &&
                        detailImageList.map((image, idx) => {
                            return (
                                <PreviewImage
                                    key={idx}
                                    image={image?.url}
                                    onDeleteImage={() => handleDeleteImageList(image)}
                                />
                            );
                        })}
                    {detailImageList?.length <= 3 && (
                        <InputFiles
                            type='image'
                            inputNotes={['Thêm hình ảnh', 'Định dạng tệp PNG, JPEG, JPG', 'Kích thước: tối đa 5MB']}
                            accept={['.png', '.jpeg', '.jpg']}
                            handleUpload={handleUploadImageList}
                            name='image'
                            control={control}
                        />
                    )}
                </div>
                {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton
                        variant='outlined'
                        onClick={() => navigateTo(history, dispatch, pagePath.productUrl)}
                        type='button'>
                        Trở lại
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Cập nhật
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default UpdateProduct;
