import axiosClient from './axiosClient';

const orderApi = {
    getListOrder: (data) => {
        const url = `/order/getAll`;
        return axiosClient.get(url, {
            params: data
        });
    },
    getOrderById: (id) => {
        const url = `/order/getOrderByID/${id}`;
        return axiosClient.get(url);
    },
    updateOrderStatus: (data) => {
        const url = `/order/updateStatus/${data.orderID}&statusCode=${data.statusCode}`;
        return axiosClient.put(url);
    },
    updateOrder: ({id, payload}) => {
        const url = `/order/updateOrder/${id}`;
        return axiosClient.put(url, payload);
    },
    deleteOrder: (id) => {
        const url = `/order​/deleteOrder​/${id}`;
        return axiosClient.delete(url);
    }
};
export default orderApi;
