import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
const CustomCard = (props) => {
    const {title, children, isDelete = false, onClick} = props;

    return (
        <div className='custom-card'>
            <div className='custom-card__header'>
                {title}
                {isDelete && <CancelIcon className='cursor-pointer' onClick={onClick} />}
            </div>

            <div className='custom-card__body'>{children}</div>
        </div>
    );
};

export default CustomCard;
