import React from 'react';
import { toast } from 'react-toastify';


const positionToast = {
	position: 'bottom-left',
	autoClose: 3000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined
};

const CustomToast = (isSuccess, message) => {
	if (isSuccess) toast.success(message, positionToast);
	else toast.error(message, positionToast);
};

export default CustomToast;


