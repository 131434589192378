import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';
import {numberToVND} from 'utils/numberToVND';
import VNSubOrderStatus from 'utils/vnSubOrderStatus';
export default function propsProvider(props) {
    const {
        dispatch,
        history,
        order,
        activePage,
        handleSearchOrder,
        setPageSize,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        handleOrderDetail,
        setPage,
        checkedData,
        setSearchOrderValue,
        modalContent,
        handleOpenModal,
        handleSubmitOrder,
        handleSubmitInvalid,
        setInputFieldProduct,
        inputFieldProduct,
        handleAddFieldsProduct,
        handleRemoveFieldsProduct,
        handleChangeInput,
        voucherList
    } = props;

    const {paginationData, orderList} = order;
    const {page, pageSize, totalPage} = paginationData[activePage] || {};

    const isCanCancel = (status) => {
        switch (status) {
            case 'DELIVERED':
                return true;
            case 'CANCELED':
                return true;
            default:
                return false;
        }
    };

    const orderTableData =
        orderList &&
        orderList.map((el) => ({
            id: el?.id,
            user: {value: el?.user.username ?? 'Khách hàng', isshow: true},
            payment_type_id: {value: el?.payment_type_id ?? 'Tiền mặt', isshow: true},
            total_price: {value: numberToVND(el?.total_price), isshow: true},
            status: {value: VNSubOrderStatus(el?.status), isshow: true},
            created_at: {value: dayjs(el?.created_at).format('HH:mm - DD/MM/YYYY'), isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <VisibilityIcon className='cursor-pointer' onClick={() => handleOrderDetail(el.id, false)} />
                        {el?.status.toUpperCase() === 'PROCESSING' ? (
                            <EditIcon className='cursor-pointer' onClick={() => handleOrderDetail(el.id, true)} />
                        ) : (
                            <EditIcon style={{visibility: 'hidden'}} />
                        )}
                        {isCanCancel(el?.status.toUpperCase()) ? (
                            <CancelIcon className='cursor-pointer' onClick={() => handleOpenModal(el)} />
                        ) : (
                            <EditIcon style={{visibility: 'hidden'}} />
                        )}
                    </div>
                ),
                isshow: true
            },
            orderObj: {
                ...el
            }
        }));
    const orderHeadCells = [
        // {
        //     id: 'Tên đơn hàng',
        //     align: 'left',
        //     padding: 'none',
        //     label: 'Tên sản phẩm'
        // },
        {
            id: 'Tên khách hàng',
            align: 'left',
            padding: 'normal',
            label: 'Tên khách hàng'
        },
        {
            id: 'Hình thức thanh toán',
            align: 'left',
            padding: 'normal',
            label: 'Hình thức thanh toán'
        },
        {
            id: 'Giá trị đơn hàng',
            align: 'left',
            padding: 'normal',
            label: 'Giá trị đơn hàng'
        },
        {
            id: 'Trạng thái đơn hàng',
            align: 'left',
            padding: 'normal',
            label: 'Trạng thái đơn hàng'
        },
        {
            id: 'Thời gian lên đơn đơn hàng',
            align: 'left',
            padding: 'normal',
            label: 'Thời gian lên đơn đơn hàng'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];

    const OrderDetailProps = {
        ...props,
        dispatch,
        history,
        handleSubmitOrder,
        handleSubmitInvalid,
        setInputFieldProduct,
        inputFieldProduct,
        handleAddFieldsProduct,
        handleRemoveFieldsProduct,
        handleChangeInput,
        voucherList,
    };
    return {
        ...props,
        dispatch,
        history,
        headCells: orderHeadCells,
        tableData: orderTableData,
        page,
        pageSize,
        totalPage,
        setPageSize,
        handleSearchOrder,
        setPage,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        orderList,
        checkedData,
        setSearchOrderValue,
        modalContent,
        OrderDetailProps
    };
}
