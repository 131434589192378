import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';
import productApi from 'api/productApi';
import voucherApi from 'api/voucherApi';
const initialState = () => {
    if (sessionStorage.getItem(Config.storageKey.voucherList)) {
        return {
            ...JSON.parse(sessionStorage.getItem(Config.storageKey.voucherList))
        };
    }
    return {
        voucherList: [],
        paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1})
    };
};

export const getListVoucher = createAsyncThunk('voucher/getListVoucher', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getListVoucher,
        payload: data
    });
});

export const getVoucherById = createAsyncThunk('voucher/getVoucherById', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getVoucherById,
        payload: data
    });
});

export const addVoucher = createAsyncThunk('voucher/addVoucher', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.addVoucher,
        payload: data
    });
});

export const updateVoucher = createAsyncThunk('voucher/updateVoucher', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.updateVoucher,
        payload: data
    });
});

export const deleteVoucher = createAsyncThunk('voucher/deleteVoucher', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.deleteVoucher,
        payload: data
    });
});

const vouchers = createSlice({
    name: 'vouchers',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const {activePage, page} = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const {activePage, pageSize} = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        },
        handleChangeTotalPage: (state, action) => {
            const {activePage, totalPage = 1} = action.payload;

            state.paginationData[activePage].totalPage = totalPage;
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getListVoucher.fulfilled, (state, action) => {
            state.isLoading = false;
            state.voucherList = action?.payload?.responseData?.rows;
            sessionStorage.setItem(Config.storageKey.voucherList, JSON.stringify(state));
        });
    }
});

const {reducer, actions} = vouchers;
export const {handleChangePage, handleChangePageSize, handleChangeTotalPage} = actions;
export default reducer;
