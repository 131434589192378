import axiosClient from './axiosClient';

const voucherApi = {
    getListVoucher: (data) => {
        const url = `/voucher/getAll`;
        return axiosClient.get(url, {
            params: data
        });
    },
    getVoucherById: (id) => {
        const url = `/voucher/getVoucherDetailByID/${id}`;
        return axiosClient.get(url);
    },
    addVoucher: (data) => {
        const url = `/voucher/createVoucher`;
        return axiosClient.post(url, data);
    },
    updateVoucher: (data) => {
        const url = `/voucher/updateVoucher/${data.id}`;
        return axiosClient.put(url, data.payload);
    },
    deleteVoucher: (id) => {
        const url = `/voucher/deleteVoucher/${id}`;
        return axiosClient.delete(url);
    }
};
export default voucherApi;
