export default function propsProvider(props) {
    const {
        sidebarList,
        navigateTo,
        breadcrumbs,
        modalContent,
        children,
    } = props;
    return {
        sidebarProps: { sidebarList, navigateTo },
        breadcrumbsProps: { breadcrumbs, navigateTo },
        modalContent,
        children
    };
}
