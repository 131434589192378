import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {voucherSelector} from 'app/selectors';
import {useHistory} from 'react-router-dom';
import VoucherContainer from './voucherContainer';

const Voucher = (props) => {
    const dispatch = useDispatch();
    const vouchers = useSelector(voucherSelector);
    const history = useHistory();
    const voucherContainerProps = {
        ...props,
        dispatch,
        history,
        vouchers
    };

    return <VoucherContainer {...voucherContainerProps} />;
};

export default Voucher;
