import {FormControl} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import navigateTo from 'components/NavigateTo';
const ProductDetail = (props) => {
    const {dispatch, history} = props;
    const {selectedProduct} = history.location.state || {};
    const defaultValues = {
        name_product: selectedProduct?.name,
        product_main_raw_list: selectedProduct?.product_main_raw_list,
        original_price: selectedProduct?.original_price,
        product_count: selectedProduct?.product_count,
        promotion_price: selectedProduct?.promotion_price === -1 ? 0 : selectedProduct?.promotion_price,
        description: selectedProduct?.description,
        images: selectedProduct?.images,
        quantity_sold: selectedProduct?.quantity_sold,
        star_rating: selectedProduct?.star_rating,
        product_like_number: selectedProduct?.product_like_number
    };
    const {control} = useForm({defaultValues});

    const ProductInputs = [
        {
            label: 'Tên sản phẩm',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'name_product'
        },
        {
            label: 'Giá gốc',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'original_price'
        },
        {
            label: 'Giá giảm',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'promotion_price'
        },
        {
            label: 'Danh sách nguyên liệu',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'product_main_raw_list'
        },
        {
            label: 'Mô tả',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'description'
        },
        {
            label: 'Số lượng sản phẩm',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'product_count'
        },
        {
            label: 'Số lượng đã bán',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'quantity_sold'
        },
        {
            label: 'Rating',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'star_rating'
        },
        {
            label: 'Số lượt thích',
            placeholder: '',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'product_like_number'
        }
    ];

    return (
        <CustomCard title='Thông tin sản phẩm'>
            <form className='mt-2 add-program' method='POST' action='#' noValidate>
                {ProductInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            <Controller
                                name={name}
                                control={control}
                                rules={rules}
                                key={idx}
                                render={({field}) => (
                                    <CustomInput label={label} {...field} {...restProps} type={type} disabled />
                                )}
                            />
                        </FormControl>
                    );
                })}
                {/* <div style={{marginBottom: 20}} className='d-flex align-items-center flex-wrap gap-3'> */}
                <div style={{marginBottom: 20, width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                    {selectedProduct.images.length > 0 &&
                        selectedProduct.images.map((image, idx) => {
                            return (
                                <PreviewImage
                                    key={idx}
                                    image={image?.file_path}
                                    onClick={() => {
                                        window.open(image?.file_path, '_blank');
                                    }}
                                />
                            );
                        })}
                </div>
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton
                        variant='outlined'
                        onClick={() => navigateTo(history, dispatch, pagePath.productUrl)}
                        type='button'>
                        Trở lại
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default ProductDetail;
