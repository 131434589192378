import axiosClient from './axiosClient';

const productApi = {
    getListProduct: (data) => {
        const url = `/product/getAll`;
        return axiosClient.get(url, {
            params: data
        });
    },
    getProductById: (id) => {
        const url = `/product/getProductDetailByID/${id}`;
        return axiosClient.get(url);
    },
    addProduct: (data) => {
        const url = `/product/createNewProduct`;
        return axiosClient.post(url, data);
    },
    updateProduct: ({id, data}) => {
        const url = `/product/updateProduct/${id}`;
        return axiosClient.put(url, data);
    },
    deleteProduct: (id) => {
        const url = `/product/deleteProduct/${id}`;
        return axiosClient.delete(url);
    },
    searchProductByKeyWord: (data) => {
        const url = `/search/keyword/${data}`;
        return axiosClient.get(url);
    }
};
export default productApi;
