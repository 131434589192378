import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import React from 'react';

const HomeMainView = (props) => {
    const {sidebarProps, breadcrumbsProps, children} = props;
    return (
        <div className='home'>
            <Header />
            <div className='home-body'>
                <Sidebar {...sidebarProps} />
                <main>
                    <CustomBreadcrumb {...breadcrumbsProps} label='admin breadcrumb' />
                    <div className='main-content'>{children}</div>
                </main>
            </div>
        </div>
    );
};

export default HomeMainView;
