import Home from 'pages/Home';
import AddProduct from './subViews/AddProduct';
import ProductDetail from './subViews/ProductDetail';
import UpdateProduct from './subViews/UpdateProduct';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import ProductList from './subViews/ProductList';

const ProductMainView = (props) => {
    const {
        history,
        addProductProps,
        productDetailProps,
        modalContent
    } = props;

    let mainComponent = null;

    switch (history.location.pathname) {
        case '/product':
            mainComponent = <ProductList {...props} />;
            break;
        case '/product/create':
            mainComponent = <AddProduct {...addProductProps} />;
            break;
        case '/product/update':
            mainComponent = <UpdateProduct {...addProductProps} />;
            break;
        case '/product/detail':
            mainComponent = <ProductDetail {...productDetailProps} />;
            break;
        default:
            mainComponent = <ProductList {...props} />;
            break;
    }

    return (
        <Home>
            <ModalPopup {...modalContent} />
            {mainComponent}
        </Home>
    );
};

export default ProductMainView;
