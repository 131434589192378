import {authSelector} from 'app/selectors';
import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import React, {useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import propsProvider from './authPropsProvider';
import {
    authenticate,
    deleteAccount,
    forgetPasswordReq,
    getMeData,
    handleSetEmail,
    resetPasswordReq,
    sendOtpDelete
} from './authSlice';
import AuthMainView from './template/AuthMainView';
import navigateTo from 'components/NavigateTo';

const AuthContainer = (props) => {
    const {dispatch, isLogin, userLoginInfo, history} = props;
    const [isSentEmail, setIsSentEmail] = useState(false);
    const [countdowns, setCountdowns] = useState(null);

    const handleInvalidSubmit = (_, event) => {
        event.target.classList.add('wasvalidated');
    };

    const handleLoginSubmit = async (data) => {
        const {isSaved, ...loginData} = data;
        const payloadTmp = {
            user_info: loginData.email,
            password: loginData.password
        };
        try {
            const res = await dispatch(authenticate(payloadTmp)).unwrap();
            const authRes = await dispatch(getMeData()).unwrap();
            if (authRes?.status === 'success') {
                navigateTo(history, dispatch, pagePath.dashboardUrl);
                CustomToast(true, 'Đăng nhập thành công');
            } else CustomToast(false, res?.message);
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra, xin vui lòng thử lại');
        }
    };

    // const handleDeleteAccount = async (data) => {
    //     const {isSaved, ...loginData} = data;
    //     const payload = {
    //         email: emailUser,
    //         otp: loginData.otp
    //     };

    //     try {
    //         const res = await dispatch(deleteAccount(payload)).unwrap();
    //         const {success, message} = res;
    //         if (success) {
    //             CustomToast(success, 'Xóa tài khoản thành công');
    //             navigateTo(pagePath.authLoginUrl);
    //         } else CustomToast(success, message);
    //     } catch (e) {
    //         CustomToast(false, 'Có lỗi xảy ra, xin vui lòng thử lại');
    //     }
    // };
    // const onSendOtpDelete = async (data) => {
    //     const {isSaved, ...loginData} = data;
    //     const payload = {
    //         email: loginData.email
    //     };
    //     try {
    //         const res = await dispatch(sendOtpDelete(payload)).unwrap();
    //         const {success, message} = res;

    //         if (success) {
    //             CustomToast(success, message);
    //             setEmail(loginData.email);
    //             setOpenFormOTP(true);
    //         } else CustomToast(success, message);
    //     } catch (e) {
    //         CustomToast(false, 'Có lỗi xảy ra, xin vui lòng thử lại');
    //     }
    // };
    // const handleConfirmEmailSubmit = async (data) => {
    //     try {
    //         setIsSentEmail(true);
    //         const res = await dispatch(forgetPasswordReq(data)).unwrap();
    //         const {success, message, data: resData} = res;

    //         if (success) {
    //             CustomToast(success, 'Xác nhận email thành công');
    //             dispatch(handleSetEmail(resData?.email));
    //             navigateTo(pagePath.authSetPasswordUrl);
    //         } else {
    //             setIsSentEmail(false);
    //             CustomToast(success, message);
    //         }
    //     } catch (e) {
    //         CustomToast(false, 'Có lỗi xảy ra, xin vui lòng thử lại');
    //     }
    // };

    // const handleSetPasswordSubmit = async (data) => {
    //     try {
    //         const setPasswordData = {
    //             email,
    //             password: data.password,
    //             password_confirmation: data.repassword
    //         };
    //         const res = await dispatch(resetPasswordReq(setPasswordData)).unwrap();
    //         const {success, message} = res;

    //         if (success) {
    //             CustomToast(success, 'Thiết lập mật khẩu thành công');
    //             setCountdowns(5);
    //         } else CustomToast(success, message);
    //     } catch (e) {
    //         CustomToast(false, 'Có lỗi xảy ra, xin vui lòng thử lại');
    //     }
    // };

    useLayoutEffect(() => {
        if (countdowns !== null) {
            if (countdowns > 0) {
                const interval = setInterval(() => setCountdowns(countdowns - 1), 1000);

                return () => clearInterval(interval);
            } else {
                // dispatch(handleSetEmail(''));
                navigateTo(pagePath.authLoginUrl);
                setIsSentEmail(false);
            }
        }
    }, [countdowns]);

    const authContainerProps = {
        ...props,
        navigateTo,
        handleInvalidSubmit,
        handleLoginSubmit,
        // handleConfirmEmailSubmit,
        // handleSetPasswordSubmit,
        isSentEmail,
        countdowns
        // email,
        // openFormOTP,
        // isSentDeleteOtp,
        // dispatch
        // handleDeleteAccount,
        // onSendOtpDelete
    };

    return <AuthMainView {...propsProvider(authContainerProps)} />;
};

export default AuthContainer;
