import {Table, TableBody, TableContainer} from '@mui/material';
import {Add, Search} from 'assets';
import ActionBar from 'components/ActionBar/ActionBar';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
import CustomTableRow from 'components/CustomTableRow/CustomTableRow';
import CustomPagination from 'components/Pagination/Pagination';

const VoucherList = (props) => {
    const {
        searchVoucherValue,
        setSearchVoucherValue,
        handleSearchVoucher,
        headCells,
        tableData,
        setPage,
        page,
        pageSize,
        totalPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        handleAddNewVoucher
    } = props;
    return (
        <CustomCard title='Danh sách khuyến mãi'>
            <div className='d-flex align-items-center gap-2'>
                <CustomInput
                    value={searchVoucherValue}
                    type='text'
                    onChange={(e) => {
                        setSearchVoucherValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            handleSearchVoucher();
                        }
                    }}
                    startIcon={<Search />}
                    placeholder={'Nhập mã khuyến mãi hoặc phương thức thanh toán để tìm...'}
                />
                <CustomButton className='flex-shrink-0' onClick={() => handleAddNewVoucher()}>
                    <Add />
                    Thêm mã voucher
                </CustomButton>
            </div>

            <div className='mt-3'>
                {checkedData.length > 0 && (
                    <ActionBar
                        selectedRow={checkedData.length}
                        rowCount={tableData.length}
                        handleMultiAction={handleDeleteSelectedRows}
                    />
                )}
            </div>

            <div className='mt-3'>
                <TableContainer className='product-table'>
                    <Table aria-labelledby='tableTitle'>
                        <CustomTableHead
                            handleCheckAll={handleSelectAllClick}
                            headCells={headCells}
                            withCheckbox
                            selectedRows={checkedData?.length}
                            rowCount={tableData?.length}
                        />
                        <TableBody>
                            {tableData &&
                                tableData.map((row, idx) => {
                                    const isItemSelected = checkedData.includes(row.id);
                                    return (
                                        <CustomTableRow
                                            key={idx}
                                            rowData={row}
                                            selected={isItemSelected}
                                            handleSelectRow={handleSelectRow}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {tableData && (
                        <div className='table-pagination'>
                            <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={page} />
                            <CustomPageSize
                                label={'Số dòng trên trang'}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pageSizeRange={[5, 10, 15, 20, 25]}
                                id={'temp'}
                            />
                        </div>
                    )}
                </TableContainer>
            </div>
        </CustomCard>
    );
};

export default VoucherList;
