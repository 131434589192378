import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import DashboardContainer from './dashboardContainer';
import {authSelector, dashSelector, orderSelector} from 'app/selectors';
import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import navigateTo from 'components/NavigateTo';

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const dashboard = useSelector(dashSelector);
    const auth = useSelector(authSelector);
    const {userLoginInfo} = auth;

    useEffect(() => {
        if (!userLoginInfo?.is_admin) {
            CustomToast(false, 'Tài khoản không được phép truy cập');
            navigateTo(history, dispatch, pagePath.logoutUrl);
        }
    });

    const history = useHistory();
    const dashboardContainerProps = {
        ...props,
        dispatch,
        history,
        dashboard
    };

    return <DashboardContainer {...dashboardContainerProps} />;
};

export default Dashboard;
