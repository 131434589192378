import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Sidebar = (props) => {
    const {sidebarList = [], navigateTo} = props;
    return (
        <div className='sidebar'>
            {sidebarList.map((sidebar, idx) => (
                <div className='sidebar-block' key={sidebar.title}>
                    <p className='sidebar-block__title'>{sidebar.title}</p>

                    <nav className='sidebar-block__items'>
                        {sidebar.items.map((item) => (
                            <div
                                className={`sidebar-item ${item.isActive ? 'sidebar-item--active' : ''}`}
                                key={item.title}
                                onClick={() => navigateTo(item.to)}>
                                <div className='d-flex align-items-center gap-3'>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </div>

                                {idx === 0 && <ArrowForwardIosIcon />}
                            </div>
                        ))}
                    </nav>
                </div>
            ))}
        </div>
    );
};

export default Sidebar;
