import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import OrderContainer from './orderContainer';
import {orderSelector, productsSelector, voucherSelector} from 'app/selectors';

const Order = (props) => {
    const dispatch = useDispatch();
    const order = useSelector(orderSelector);
    const product = useSelector(productsSelector);
    const voucher = useSelector(voucherSelector);
    const {productList} = product;
    const {voucherList} = voucher;

    const history = useHistory();
    const orderContainerProps = {
        ...props,
        dispatch,
        history,
        order,
        productList,
        voucherList
    };

    return <OrderContainer {...orderContainerProps} />;
};

export default Order;
