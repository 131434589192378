import Home from 'pages/Home';
import AddVoucher from './subViews/AddVoucher';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import VoucherList from './subViews/VoucherList';

const VoucherMainView = (props) => {
    const {history, addVoucherProps, modalContent} = props;
    let mainComponent = null;
    switch (history.location.pathname) {
        case '/voucher':
            mainComponent = <VoucherList {...props} />;
            break;
        case '/voucher/create':
            mainComponent = <AddVoucher {...addVoucherProps} />;
            break;
        case '/voucher/update':
            mainComponent = <AddVoucher {...addVoucherProps} />;
            break;
        case '/voucher/detail':
            mainComponent = <AddVoucher {...addVoucherProps} />;
            break;
        default:
            mainComponent = <VoucherList {...props} />;
            break;
    }

    return (
        <Home>
            <ModalPopup {...modalContent} />
            {mainComponent}
        </Home>
    );
};

export default VoucherMainView;
