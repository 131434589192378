import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';
import productApi from 'api/productApi';
const initialState = () => {
    if (sessionStorage.getItem(Config.storageKey.productList)) {
        return {
            ...JSON.parse(sessionStorage.getItem(Config.storageKey.productList))
        };
    }
    return {
        productList: [],
        paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1})
    };
};

export const getListProduct = createAsyncThunk('product/getListProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getListProduct,
        payload: data
    });
});

export const getProductById = createAsyncThunk('product/getProductDetailById', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProductById,
        payload: data
    });
});

export const addProduct = createAsyncThunk('product/addProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.addProduct,
        payload: data
    });
});

export const updateProduct = createAsyncThunk('product/updateProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.updateProduct,
        payload: data
    });
});

export const deleteProduct = createAsyncThunk('product/deleteProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.deleteProduct,
        payload: data
    });
});

export const searchProductByKeyWord = createAsyncThunk('product/searchProductByKeyWord', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.searchProductByKeyWord,
        payload: data
    });
});

const products = createSlice({
    name: 'products',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const {activePage, page} = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const {activePage, pageSize} = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        },
        handleChangeTotalPage: (state, action) => {
            const {activePage, totalPage = 1} = action.payload;
            state.paginationData[activePage].totalPage = totalPage;
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getListProduct.fulfilled, (state, action) => {
            state.isLoading = false;
            state.productList = action?.payload?.responseData?.rows;
            sessionStorage.setItem(Config.storageKey.productList, JSON.stringify(state));
        });
        builders.addCase(searchProductByKeyWord.fulfilled, (state, action) => {
            state.isLoading = false;
            state.productList = action?.payload?.responseData?.rows;
            sessionStorage.setItem(Config.storageKey.productList, JSON.stringify(state));
        });
    }
});

const {reducer, actions} = products;
export const {handleChangePage, handleChangePageSize, handleChangeTotalPage} = actions;
export default reducer;
