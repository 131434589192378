import {useEffect, useState} from 'react';
import propsProvider from './orderPropsProvider';
import {validate as isValidUUID} from 'uuid';
import {
    getListOrder,
    getOrderByID,
    handleChangePage,
    handleChangePageSize,
    handleChangeTotalPage,
    updateOrder,
    updateOrderStatus
} from './orderSlice';
import {calcTotalPage} from 'utils/calcTotalPage';
import CustomToast from 'components/CustomToast';
import OrderMainView from './template/OrderMainView';
import {pagePath} from 'configuration/routeConfig';
import navigateTo from 'components/NavigateTo';
import {getVoucherById} from 'pages/Voucher/voucherSlice';
import dayjs from 'dayjs';
import {getProductById} from 'pages/Product/productSlice';
const OrderContainer = (props) => {
    const {order, dispatch, history, productList, voucherList} = props;
    const {orderList, paginationData} = order;
    const [activePage, setActivePage] = useState(0);

    const [inputFieldProduct, setInputFieldProduct] = useState([]);
    const [deleteProduct, setDeleteProduct] = useState([]);
    const [searchOrderValue, setSearchOrderValue] = useState('');
    const [checkedData, setCheckedData] = useState([]);
    const [modalContent, setModalContent] = useState({});
    useEffect(() => {
        fetchOrder();
    }, [paginationData]);
    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        dispatch(handleChangeTotalPage({activePage, totalPage: value}));
    };

    const fetchOrder = async () => {
        const resFetch = await dispatch(
            getListOrder({
                filters: searchOrderValue ? `product.name@=${searchOrderValue}` : '',
                Sorts: '',
                currentPage: paginationData[activePage]?.page ?? 1,
                pageSize: paginationData[activePage]?.pageSize ?? 10
            })
        ).unwrap();
        if (resFetch?.status) {
            const {responseData} = resFetch;
            // setListProductOrder(responseData?.data);
            setTotalPage(responseData?.count, paginationData[activePage]?.pageSize);
        }
    };

    const handleSearchOrder = () => {
        fetchOrder();
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage, page: value}));
    };
    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage, pageSize: value}));
        setPage(1);
    };

    const handleOpenModal = async (order) => {
        try {
            const modalPopupState = {
                title: 'Xác nhận',
                closeText: 'Hủy'
            };
            let contentText = '';
            Object.assign(modalPopupState, {
                onConfirm: async () => {
                    // const res = await dispatch(
                    //     updateOrderStatus({orderID: order?.id, statusCode: 'CANCELED'})
                    // ).unwrap();
                    // if (res.status === 'success') {
                    //     setCheckedData([]);
                    //     CustomToast(true, 'Thao tác thành công');
                    //     fetchOrder();
                    // } else {
                    //     CustomToast(false, 'Thao tác thất bại');
                    // }
                },
                confirmText: 'Đồng ý'
            });
            contentText = `Bạn có chắc muốn HUỶ đơn hàng " ${order?.product?.name} " này?`;
            modalPopupState.content = (
                <div
                    className='d-flex justify-content-center align-items-center gap-2'
                    style={{fontWeight: 'bold', fontFamily: 'sans-serif'}}>
                    {contentText}
                </div>
            );
            setModalContent(modalPopupState);
        } catch (err) {
            console.error('unexpected error');
        }
    };
    const handleSelectAllClick = (event) => {
        if (orderList) {
            if (event.target.checked) {
                let newSelected = [];
                if (activePage === 0) {
                    newSelected = orderList.map((data) => data?.id);
                } else {
                    newSelected = orderList.map((data) => data?.id);
                }
                setCheckedData(newSelected);
                return;
            }
            setCheckedData([]);
        }
    };
    const handleSelectRow = (event, id) => {
        const selectedIndex = checkedData.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedData, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedData.slice(1));
        } else if (selectedIndex === checkedData.length - 1) {
            newSelected = newSelected.concat(checkedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(checkedData.slice(0, selectedIndex), checkedData.slice(selectedIndex + 1));
        }

        setCheckedData(newSelected);
    };

    const handleDeleteSelectedRows = async () => {};

    const handleOrderDetail = async (id, isEdit) => {
        const res = await dispatch(getOrderByID(id)).unwrap();
        if (res.status === 'success') {
            const pathname = isEdit ? pagePath.orderUpdateUrl : pagePath.orderDetailUrl;
            history.push({
                pathname,
                state: {selectedOrder: res.responseData, isEdit: isEdit}
            });
        }
    };

    const handleGetProductById = (id) => {
        return productList.find((product) => product.id === id);
    };

    const handleAddFieldsProduct = () => {
        setInputFieldProduct([
            ...inputFieldProduct,
            {
                product_id: '',
                count: 0,
                options: productList.map((item) => ({value: item.id, label: item.name}))
            }
        ]);
    };

    const handleRemoveFieldsProduct = (index) => {
        const values = [...inputFieldProduct];
        const isDuplicate = deleteProduct.some((item) => item.product_code === values[index].product_code);
        if (!isDuplicate) {
            setDeleteProduct([...deleteProduct, values[index]]);
        }
        values.splice(index, 1);
        setInputFieldProduct(values);
    };

    const handleChangeInput = (event, index) => {
        const values = [...inputFieldProduct];
        if (event?.target?.value === undefined || event.target.value === null) {
            values[index]['product_id'] = event;
        } else {
            values[index]['count'] = event.target.value;
        }
        setInputFieldProduct(values);
    };

    const handleCalculateDiscount = (voucher_id, order_id) => {
        if (!voucher_id) return -1;
        let discount = 0;
        const voucher = voucherList.find((item) => item.id === voucher_id);
        const order = orderList.find((item) => item.id === order_id);
        const {order_items} = order;
        order_items.forEach((item) => {
            discount += item.total;
        });
        const {sale_percentage, max_reduce, minimum_price} = voucher;
        if (discount >= minimum_price) {
            let total_tmp = discount * (sale_percentage / 100);
            if (total_tmp > max_reduce) {
                total_tmp = max_reduce;
            }
            discount = total_tmp;
        }
        return discount;
    };

    const handleSubmitOrder = async (data) => {
        const {id, status, shipping_fee, voucher_id, old_voucher_id, old_status, discount_price} = data;
        if (inputFieldProduct.length === 0) {
            CustomToast(false, 'Vui lòng thêm sản phẩm cho đơn hàng');
            return false;
        }

        let voucher_id_tmp = null;
        let product_add = [];
        let product_delete = [];

        if (status !== old_status) {
            const res = await dispatch(updateOrderStatus({orderID: data?.id, statusCode: status})).unwrap();
            if (res.status !== 'success') {
                CustomToast(false, 'Thao tác thất bại');
                return false;
            }
        }

        if (isValidUUID(voucher_id)) {
            voucher_id_tmp = voucher_id;
        }

        inputFieldProduct.forEach((item) => {
            if (isValidUUID(item.product_id)) {
                if (item?.count > 0) {
                    product_add.push({product_id: item?.product_id, count: parseInt(item?.count)});
                }
            }
        });

        deleteProduct.forEach((item) => {
            if (!isValidUUID(item.product_id)) {
                product_delete.push({
                    product_id: item?.product_id,
                    count: parseInt(item?.count),
                    total: parseInt(item?.total)
                });
            }
        });

        const payload = {
            deleteItems: product_delete,
            newItems: product_add,
            voucher_id: voucher_id_tmp ? voucher_id_tmp : old_voucher_id,
            shipping_fee: shipping_fee,
            discount_price:
                handleCalculateDiscount(voucher_id_tmp, id) === -1
                    ? discount_price
                    : handleCalculateDiscount(voucher_id_tmp, id)
        };

        const res = await dispatch(updateOrder({id, payload})).unwrap();
        if (res.status === 'success') {
            CustomToast(true, 'Thao tác thành công');
            fetchOrder();
            navigateTo(history, dispatch, pagePath.orderUrl);
        } else {
            CustomToast(false, res.message);
        }
    };
    const handleSubmitInvalid = (_, event) => {};

    const orderContainerProps = {
        ...props,
        activePage,
        handleSearchOrder,
        setPageSize,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        handleOrderDetail,
        setPage,
        checkedData,
        setSearchOrderValue,
        modalContent,
        handleOpenModal,
        order,
        handleSubmitOrder,
        handleSubmitInvalid,
        setInputFieldProduct,
        inputFieldProduct,
        handleAddFieldsProduct,
        handleRemoveFieldsProduct,
        handleChangeInput,
        voucherList
    };

    return <OrderMainView {...propsProvider(orderContainerProps)} />;
};

export default OrderContainer;
