import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import DiscountIcon from '@mui/icons-material/Discount';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {authSelector} from 'app/selectors';
import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'pages/Auth/authSlice';
import {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import propsProvider from './homePropsProvider';
import HomeMainView from './template/HomeMainView';

const HomeContainer = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const {pathname} = location;
    const auth = useSelector(authSelector);
    const [activePage, setActivePage] = useState(1);
    const [checkedData, setCheckedData] = useState([]);

    const [sidebarList, setSidebarList] = useState([
        {
            title: 'Thiết lập thông tin',
            items: [
                {
                    title: 'Dashboard',
                    icon: <DashboardIcon />,
                    to: pagePath.dashboardUrl,
                    isActive: true,
                    items: []
                },
                {
                    title: 'Sản phẩm',
                    icon: <LocalFloristIcon />,
                    to: pagePath.productUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.productCreateUrl
                        },
                        {
                            title: 'Chi tiết',
                            to: pagePath.productDetailUrl
                        },
                        {
                            title: 'Chỉnh sửa',
                            to: pagePath.productUpdateUrl
                        }
                    ]
                },
                {
                    title: 'Ưu Đãi',
                    icon: <DiscountIcon />,
                    to: pagePath.voucherUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Thêm mới',
                            to: pagePath.voucherCreateUrl
                        },
                        {
                            title: 'Chỉnh sửa',
                            to: pagePath.voucherUpdateUrl
                        },
                        {
                            title: 'Chi tiết',
                            to: pagePath.voucherDetailUrl
                        }
                    ]
                },
                {
                    title: 'Đơn hàng',
                    icon: <BookmarkBorderIcon />,
                    to: pagePath.orderUrl,
                    isActive: false,
                    items: [
                        {
                            title: 'Chỉnh sửa',
                            to: pagePath.orderUpdateUrl
                        },
                        {
                            title: 'Thông tin đơn',
                            to: pagePath.orderDetailUrl
                        }
                    ]
                }
            ]
        },
        {
            items: [{title: 'Đăng xuất', icon: <LogoutIcon />, to: pagePath.logoutUrl}]
        }
    ]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const navigateTo = (path) => {
        try {
            if (path === pagePath.logoutUrl) {
                dispatch(logout()).then((res) => history.push(pagePath.authLoginUrl));
            } else {
                history.push(path);
            }
        } catch (e) {
            CustomToast(false, 'Có lỗi xảy ra');
        }
    };

    const checkIsLogin = () => {
        const {token} = auth;
        if (!token) {
            history.push(pagePath.authLoginUrl);
        }
    };
    useEffect(() => {
        checkIsLogin();
    }, []);

    useLayoutEffect(() => {
        const newBreadcrumbs = [];
        const newSidebarList = [...sidebarList];

        newSidebarList[0].items = newSidebarList[0].items.map((item, idx) => {
            const isItemActive = pathname.includes(item.to);
            item.isActive = isItemActive;

            if (isItemActive) {
                setActivePage(idx);
                setCheckedData([]);
                newBreadcrumbs.push({title: item.title, to: item.to});

                item.items.forEach((subItem) => {
                    if (pathname.includes(subItem.to)) {
                        newBreadcrumbs.push({title: subItem.title, to: subItem.to});
                    }
                });
            }

            return item;
        });

        setSidebarList(newSidebarList);
        setBreadcrumbs(newBreadcrumbs);
    }, [pathname]);

    const homeContainerProps = {
        ...props,
        breadcrumbs,
        sidebarList,
        navigateTo
    };

    return <HomeMainView {...propsProvider(homeContainerProps)} />;
};

export default HomeContainer;
