import {useEffect, useState} from 'react';
import propsProvider from './productPropsProvider';
import {
    addProduct,
    deleteProduct,
    getListProduct,
    getProductById,
    handleChangePage,
    handleChangePageSize,
    handleChangeTotalPage,
    searchProductByKeyWord,
    updateProduct
} from './productSlice';
import CustomToast from 'components/CustomToast';
import {pagePath} from 'configuration/routeConfig';
import {calcTotalPage} from 'utils/calcTotalPage';
import ProductMainView from './template/ProductMainView';
import {getBase64} from 'utils/getBase64';
import {deleteFile, uploadFile} from 'pages/Home/homeSlice';
import navigateTo from 'components/NavigateTo';
const ProductContainer = (props) => {
    const {products, dispatch, history} = props;
    const {productList, paginationData} = products;
    const [activePage, setActivePage] = useState(1);
    const [checkedData, setCheckedData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [searchProductsValue, setSearchProductsValue] = useState('');
    const [detailImageList, setDetailImageList] = useState([]);
    const [modalContent, setModalContent] = useState({});
    useEffect(() => {
        fetchProducts();
    }, [paginationData]);
    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        dispatch(handleChangeTotalPage({activePage, totalPage: value}));
    };
    const fetchProducts = async () => {
        const resFetch = await dispatch(
            getListProduct({
                filters: '',
                Sorts: '',
                currentPage: paginationData[activePage]?.page ?? 1,
                pageSize: paginationData[activePage]?.pageSize ?? 10
            })
        ).unwrap();
        if (resFetch?.status) {
            const {responseData} = resFetch;
            setTotalPage(responseData?.count, paginationData[activePage]?.pageSize);
        }
    };

    const handleSearchProduct = async () => {
        if (Object.keys(searchProductsValue).length !== 0) {
            await dispatch(searchProductByKeyWord(searchProductsValue)).unwrap();
        } else {
            fetchProducts();
        }
    };
    const setPage = (value) => {
        dispatch(handleChangePage({activePage, page: value}));
    };
    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage, pageSize: value}));
        setPage(1);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            if (activePage === 0) {
                newSelected = productList.map((data) => data?.id);
            } else {
                newSelected = productList.map((data) => data?.id);
            }
            setCheckedData(newSelected);
            return;
        }
        setCheckedData([]);
    };
    const handleSelectRow = (event, id) => {
        const selectedIndex = checkedData.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedData, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedData.slice(1));
        } else if (selectedIndex === checkedData.length - 1) {
            newSelected = newSelected.concat(checkedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(checkedData.slice(0, selectedIndex), checkedData.slice(selectedIndex + 1));
        }

        setCheckedData(newSelected);
    };

    const handleDeleteSelectedRows = async () => {};

    const handleUploadImageList = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 5 * 1024 * 1024) {
            CustomToast(false, 'Tệp vượt quá giới hạn dung lượng cho phép');
            return;
        }

        const previewUrl = await getBase64(file);
        setDetailImageList([...detailImageList, {url: previewUrl, file}]);
    };

    const handleDeleteImageList = (e) => {
        const newFiles = detailImageList.filter((item) => item !== e);
        setDetailImageList(newFiles);
    };

    const handleAddProduct = async (data) => {
        const {product_name, original_price, product_count, product_main_raw_list} = data;
        const detailImageRes = [];
        if (detailImageList.length > 0) {
            await Promise.all(
                detailImageList.map(async (item) => {
                    let file = new FormData();
                    file.append('file', item.file);
                    const fileRes = await dispatch(uploadFile(file)).unwrap();
                    if (fileRes.status !== 'success') {
                        return;
                    }
                    detailImageRes.push({
                        id: fileRes?.responseData?.id,
                        file_path: fileRes?.responseData?.file_path
                    });
                })
            );
        }
        const payload = {
            name: product_name,
            product_main_raw_list: product_main_raw_list,
            original_price: original_price,
            product_count: product_count,
            images: detailImageRes
        };
        const res = await dispatch(addProduct(payload)).unwrap();
        if (res?.status) {
            CustomToast('success', `Thêm sản phẩm thành công`);
            navigateTo(history, dispatch, pagePath.productUrl);
            fetchProducts();
        } else {
            CustomToast(false, `Thêm sản phẩm  không thành công`);
        }
    };

    const handleUpdateProduct = async (data) => {
        const {
            description,
            quantity_sold,
            product_count,
            original_price,
            promotion_price,
            product_main_raw_list,
            images,
            product_name
        } = data;
        const detailImageRes = [];
        if (detailImageList.length > 0) {
            await Promise.all(
                detailImageList.map(async (item) => {
                    let file = new FormData();
                    file.append('file', item.file);
                    const fileRes = await dispatch(uploadFile(file)).unwrap();
                    if (fileRes.status !== 'success') {
                        return;
                    }
                    detailImageRes.push({
                        id: fileRes?.responseData?.id,
                        file_path: fileRes?.responseData?.file_path,
                        avatar: null
                    });
                })
            );
        }
        if (images.length > 0) {
            detailImageRes.push(...images);
        }
        const payload = {
            name: product_name,
            description: description,
            quantity_sold: quantity_sold,
            product_count: product_count,
            original_price: original_price,
            promotion_price: promotion_price,
            product_main_raw_list: product_main_raw_list,
            images: detailImageRes
        };
        const res = await dispatch(updateProduct({id: data.id, data: payload})).unwrap();
        if (res?.status) {
            CustomToast('success', `Cập nhật sản phẩm thành công`);
            navigateTo(history, dispatch, pagePath.productUrl);
            fetchProducts();
        } else {
            CustomToast(false, `Cập nhật sản phẩm không thành công`);
        }
    };

    const handleSubmitInvalid = (_, event) => {};

    const handleProductDetail = async (id, isEdit) => {
        const res = await dispatch(getProductById(id)).unwrap();
        if (res.status === 'success') {
            setIsEdit(isEdit);
            setSelectedProduct(res.responseData);
            const pathname = isEdit ? pagePath.productUpdateUrl : pagePath.productDetailUrl;
            history.push({
                pathname,
                state: {selectedProduct: res.responseData}
            });
        }
    };

    const handleDeleteProduct = async (product) => {
        try {
            const modalPopupState = {
                title: 'Xác nhận',
                closeText: 'Hủy'
            };
            let contentText = '';
            Object.assign(modalPopupState, {
                onConfirm: async () => {
                    const res = await dispatch(deleteProduct(product.id)).unwrap();
                    if (res.status === 'success') {
                        fetchProducts();
                        CustomToast(true, 'Thao tác thành công');
                    } else {
                        CustomToast(false, `Thao tác thất bại`);
                    }
                },
                confirmText: 'Đồng ý'
            });
            contentText = `Bạn có chắc muốn xoá sản phẩm " ${product?.name} " này?`;
            modalPopupState.content = (
                <div
                    className='d-flex justify-content-center align-items-center gap-2'
                    style={{fontWeight: 'bold', fontFamily: 'sans-serif'}}>
                    {contentText}
                </div>
            );
            setModalContent(modalPopupState);
        } catch (err) {
            console.error('unexpected error');
        }
    };

    const handleDeleteImageApi = async (id) => {
        const res = await dispatch(deleteFile(id)).unwrap();
        if (res?.status === 'success') {
            CustomToast('success', `Xoá hình thành công`);
            navigateTo(history, dispatch, pagePath.productUpdateUrl);
        } else {
            CustomToast(false, `Xoá hình thất bại`);
        }
    };

    const productContainerProps = {
        ...props,
        searchProductsValue,
        setSearchProductsValue,
        handleSearchProduct,
        dispatch,
        history,
        productList,
        setPage,
        activePage,
        products,
        setPageSize,
        checkedData,
        handleDeleteSelectedRows,
        handleSelectRow,
        handleSelectAllClick,
        detailImageList,
        handleDeleteImageList,
        handleUploadImageList,
        handleAddProduct,
        handleSubmitInvalid,
        handleProductDetail,
        selectedProduct,
        isEdit,
        handleDeleteProduct,
        handleDeleteImageApi,
        handleUpdateProduct,
        modalContent
    };

    return <ProductMainView {...propsProvider(productContainerProps)} />;
};

export default ProductContainer;
