import {useEffect, useState} from 'react';
import propsProvider from './voucherPropsProvider';
import {
    addVoucher,
    deleteVoucher,
    getListVoucher,
    getVoucherById,
    handleChangePage,
    handleChangePageSize,
    handleChangeTotalPage,
    updateVoucher
} from './voucherSlice';
import {calcTotalPage} from 'utils/calcTotalPage';
import VoucherMainView from './template/VoucherMainView';
import CustomToast from 'components/CustomToast';
import navigateTo from 'components/NavigateTo';
import {pagePath} from 'configuration/routeConfig';
const VoucherContainer = (props) => {
    const {vouchers, dispatch, history} = props;
    const {voucherList, paginationData} = vouchers;
    const [activePage, setActivePage] = useState(0);
    const [searchVoucherValue, setSearchVoucherValue] = useState('');
    const [checkedData, setCheckedData] = useState([]);
    const [modalContent, setModalContent] = useState({});
    useEffect(() => {
        fetchVoucher();
    }, [paginationData]);
    const setTotalPage = (count, pageSize) => {
        const value = calcTotalPage(count, pageSize);
        dispatch(handleChangeTotalPage({activePage, totalPage: value}));
    };
    const fetchVoucher = async () => {
        const resFetch = await dispatch(
            getListVoucher({
                filters: '',
                Sorts: '',
                currentPage: paginationData[activePage]?.page ?? 1,
                pageSize: paginationData[activePage]?.pageSize ?? 10,
                keyword: searchVoucherValue ?? '',
            })
        ).unwrap();
        if (resFetch?.status) {
            const {responseData} = resFetch;
            setTotalPage(responseData?.count, paginationData[activePage]?.pageSize);
        }
    };

    const handleSearchVoucher = () => {
        fetchVoucher();
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage, page: value}));
    };
    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage, pageSize: value}));
        setPage(1);
    };

    const handleSelectAllClick = (event) => {
        if (voucherList) {
            if (event.target.checked) {
                let newSelected = [];
                if (activePage === 0) {
                    newSelected = voucherList.map((data) => data?.id);
                } else {
                    newSelected = voucherList.map((data) => data?.id);
                }
                setCheckedData(newSelected);
                return;
            }
            setCheckedData([]);
        }
    };
    const handleSelectRow = (event, id) => {
        const selectedIndex = checkedData.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedData, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedData.slice(1));
        } else if (selectedIndex === checkedData.length - 1) {
            newSelected = newSelected.concat(checkedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(checkedData.slice(0, selectedIndex), checkedData.slice(selectedIndex + 1));
        }
        setCheckedData(newSelected);
    };

    const handleDeleteSelectedRows = async () => {};

    const handleSubmitVoucher = async (data) => {
        const {isEdit} = history?.location?.state;
        const {
            id,
            code,
            description,
            sale_percentage,
            valid_to,
            valid_from,
            minimum_price,
            max_reduce,
            is_limited,
            payment_method
        } = data;
        const payload = {
            code: code,
            description: description,
            sale_percentage: sale_percentage,
            valid_to: valid_to,
            valid_from: valid_from,
            minimum_price: minimum_price,
            max_reduce: max_reduce,
            is_limited: is_limited,
            payment_method: payment_method
        };
        const res = await dispatch(isEdit ? updateVoucher({id, payload}) : addVoucher(payload)).unwrap();
        if (res?.status === 'success') {
            CustomToast('success', `${isEdit ? 'Chỉnh sửa' : 'Thêm'} mã khuyến thành công`);
            navigateTo(history, dispatch, pagePath.voucherUrl);
            fetchVoucher();
        } else {
            CustomToast(false, `${isEdit ? 'Chỉnh sửa' : 'Thêm'} mã khuyến không thành công`);
        }
    };
    const handleSubmitInvalid = (_, event) => {
        // event.target.classList.add('wasvalidated');
    };

    const handleAddNewVoucher = () => {
        history.push({
            pathname: pagePath.voucherCreateUrl,
            state: {selectedVoucher: {}, isEdit: false}
        });
    };

    const handleVoucherDetail = async (id, isEdit) => {
        const res = await dispatch(getVoucherById(id)).unwrap();
        if (res.status === 'success') {
            const pathname = isEdit ? pagePath.voucherUpdateUrl : pagePath.voucherDetailUrl;
            history.push({
                pathname,
                state: {selectedVoucher: res.responseData, isEdit: isEdit}
            });
        }
    };

    const handleDeleteVoucher = async (voucher) => {
        try {
            const modalPopupState = {
                title: 'Xác nhận',
                closeText: 'Hủy'
            };
            let contentText = '';
            Object.assign(modalPopupState, {
                onConfirm: async () => {
                    const res = await dispatch(deleteVoucher(voucher.id)).unwrap();
                    if (res.status === 'success') {
                        fetchVoucher();
                        CustomToast(true, 'Thao tác thành công');
                    } else {
                        CustomToast(false, `Thao tác thất bại`);
                    }
                },
                confirmText: 'Đồng ý'
            });
            contentText = `Bạn có chắc muốn mã " ${voucher?.code} " này?`;
            modalPopupState.content = (
                <div
                    className='d-flex justify-content-center align-items-center gap-2'
                    style={{fontWeight: 'bold', fontFamily: 'sans-serif'}}>
                    {contentText}
                </div>
            );
            setModalContent(modalPopupState);
        } catch (err) {
            console.error('unexpected error');
        }
    };

    const productContainerProps = {
        ...props,
        searchVoucherValue,
        setSearchVoucherValue,
        handleSearchVoucher,
        dispatch,
        history,
        voucherList,
        setPage,
        activePage,
        vouchers,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        handleDeleteSelectedRows,
        handleSubmitVoucher,
        handleSubmitInvalid,
        handleVoucherDetail,
        handleDeleteVoucher,
        handleAddNewVoucher,
        modalContent
    };

    return <VoucherMainView {...propsProvider(productContainerProps)} />;
};

export default VoucherContainer;
