import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import fileApi from 'api/fileApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1})
    };
};

export const uploadFile = createAsyncThunk('file/uploadFile', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: fileApi.uploadFile,
        payload: data
    });
});

export const deleteFile = createAsyncThunk('file/deleteFile', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: fileApi.deleteFile,
        payload: data
    });
});

const home = createSlice({
    name: 'home',
    initialState,
    reducers: {
        // handleChangePage: (state, action) => {
        //     const {activePage, page} = action.payload;
        //     state.paginationData[activePage].page = page;
        // },
        // handleChangePageSize: (state, action) => {
        //     const {activePage, pageSize} = action.payload;
        //     state.paginationData[activePage].pageSize = pageSize;
        // },
        // handleChangeTotalPage: (state, action) => {
        //     const {activePage, totalPage = 1} = action.payload;
        //     state.paginationData[activePage].totalPage = totalPage;
        // }
    },
    extraReducers: (builders) => {}
});

const {reducer, actions} = home;
export const {handleChangePage, handleChangePageSize, handleChangeTotalPage} = actions;
export default reducer;
