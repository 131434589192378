import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import { pagePath } from 'configuration/routeConfig';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const defaultValues = {
    email: '',
    otp: '',
    isSaved: false
};

const DeleteAccount = (props) => {
    const { handleDeleteAccount, navigateTo, onSendOtpDelete, openFormOTP, goBackSendEmailDelete, isSentDeleteOtp } = props;
    const authInputs = [
        {
            name: 'email',
            label: 'Email',
            placeholder: 'Vui lòng nhập email bạn đã đăng ký',
            type: 'text'
        }

    ];
    const { handleSubmit, control, watch } = useForm({ defaultValues });
    const isFormValid = watch(['email']).every((value) => value.length > 0);
    const EnterOTP = () => {

        const {
            handleSubmit,
            control,
            watch,
            formState: { isSubmitting }
        } = useForm({ defaultValues });
        const isFormValid = watch('otp').length > 0 && !isSubmitting;
        const otpInput = [
            {
                name: 'otp',
                label: 'otp',
                placeholder: 'Nhập otp của bạn tại đây',
                type: 'text',
                disabled: isSubmitting
            },

        ];

        return (
            <form method='DELETE' action='#' noValidate onSubmit={handleSubmit(handleDeleteAccount)}>
                <h4 className='mb-1'>Xóa tài khoản</h4>
                <p className='form-subTitle'>
                    Chúng tôi đã gửi mã otp vào hộp thư của bạn, bạn vui lòng nhập otp bên dưới
                </p>

                <div className='form-content d-flex flex-column'>
                    {otpInput.map((input) => {
                        const { name } = input;

                        return (
                            <FormControl key={name} className='form-content__control'>
                                <Controller
                                    name={name}
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} {...input} />}
                                />
                            </FormControl>
                        );
                    })}
                </div>

                <CustomButton disabled={!isFormValid} fullwidth className='mb-2'>
                    {isSentDeleteOtp ? 'Đã gửi' : 'Gửi'}
                </CustomButton>
                <CustomButton type='button' variant='text' fullwidth onClick={goBackSendEmailDelete}>
                    Trở về
                </CustomButton>
            </form>
        );
    };
    const EnterMail = () => {
        return (
            <form method='POST' action='#' noValidate onSubmit={handleSubmit(onSendOtpDelete)}>
                <h4>Chào mừng trở lại!</h4>

                <div className='form-content d-flex flex-column'>
                    {authInputs.map((input) => {
                        const { name } = input;

                        return (
                            <FormControl key={name} className='form-content__control'>
                                <Controller
                                    name={name}
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} {...input} />}
                                />
                            </FormControl>
                        );
                    })}
                </div>

                <div className='form-helper'>
                    <div className='form-helper__variant' onClick={() => navigateTo(pagePath.authForgotPasswordUrl)}>
                        Quên mật khẩu?
                    </div>
                </div>

                <CustomButton disabled={!isFormValid} fullwidth>
                    Gửi mã otp
                </CustomButton>
            </form>
        );
    }
    return (
        <div>
            {openFormOTP ? <EnterOTP /> : <EnterMail />}
        </div>


    );
};

export default DeleteAccount;
