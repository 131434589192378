import NotFound from 'components/NotFound';
import Auth from 'pages/Auth';
import Dashboard from 'pages/Dashboard';
import Order from 'pages/Order';
import Product from 'pages/Product';
import Voucher from 'pages/Voucher';

const rootUrl = '/';
const dashboardUrl = '/dashboard';
///////////////////////////////////
const productUrl = '/product';
const productCreateUrl = '/product/create';
const productDetailUrl = '/product/detail';
const productUpdateUrl = '/product/update';
///////////////////////////////////
const voucherUrl = '/voucher';
const voucherCreateUrl = '/voucher/create';
const voucherUpdateUrl = '/voucher/update';
const voucherDetailUrl = '/voucher/detail';
///////////////////////////////////
const orderUrl = '/order';
const orderUpdateUrl = '/order/update';
const orderDetailUrl = '/order/detail';
///////////////////////////////////
const logoutUrl = '/logout';
const authUrl = '/auth';
const authLoginUrl = '/auth/login';
const authForgotPasswordUrl = '/auth/forgot-password';
const authSetPasswordUrl = '/auth/set-password';
const notFoundUrl = '/*';
const deleteAccount = '/auth/deleteAccount';
export const routeList = [
    // Keep "Not found page" the last one
    {path: rootUrl, component: Product, isPrivate: false},
    {path: dashboardUrl, component: Dashboard, isPrivate: false},

    ///////////////////////////////////////////////////
    {path: productUrl, component: Product, isPrivate: false},
    {path: productDetailUrl, component: Product, isPrivate: false},
    {path: productCreateUrl, component: Product, isPrivate: false},
    {path: productUpdateUrl, component: Product, isPrivate: false},
    ///////////////////////////////////////////////////
    {path: voucherUrl, component: Voucher, isPrivate: false},
    {path: voucherCreateUrl, component: Voucher, isPrivate: false},
    {path: voucherUpdateUrl, component: Voucher, isPrivate: false},
    {path: voucherDetailUrl, component: Voucher, isPrivate: false},
    ///////////////////////////////////////////////////
    {path: orderUrl, component: Order, isPrivate: false},
    {path: orderUpdateUrl, component: Order, isPrivate: false},
    {path: orderDetailUrl, component: Order, isPrivate: false},
    ///////////////////////////////////////////////////
    {path: authUrl, component: Auth},
    {path: authLoginUrl, component: Auth},
    {path: authForgotPasswordUrl, component: Auth},
    {path: authSetPasswordUrl, component: Auth},
    {path: deleteAccount, component: Auth},
    {path: notFoundUrl, component: NotFound}
];

export const redirectList = [{from: authUrl, to: authLoginUrl}];

export const pagePath = {
    productUrl,
    productDetailUrl,
    productCreateUrl,
    productUpdateUrl,
    //////////////////
    voucherUrl,
    voucherCreateUrl,
    voucherUpdateUrl,
    voucherDetailUrl,
    //////////////////
    orderUrl,
    orderUpdateUrl,
    orderDetailUrl,
    //////////////////
    dashboardUrl,
    //////////////////

    rootUrl,
    logoutUrl,
    authUrl,
    authLoginUrl,
    authForgotPasswordUrl,
    authSetPasswordUrl,
    deleteAccount
};
