import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCard from 'components/CustomCard';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {CheckOutlined, CloseOutlined} from '@mui/icons-material';
import GroupInput from 'components/GroupInput/GroupInput';
import navigateTo from 'components/NavigateTo';
import {Select} from 'antd';

const AddVoucher = (props) => {
    const {dispatch, history, handleSubmitVoucher, handleSubmitInvalid} = props;
    const {selectedVoucher = {}, isEdit} = history.location.state || {};
    const defaultValues = {
        id: selectedVoucher?.id,
        code: selectedVoucher?.code,
        description: selectedVoucher?.description,
        sale_percentage: selectedVoucher?.sale_percentage,
        valid_to: selectedVoucher?.valid_to,
        valid_from: selectedVoucher?.valid_from,
        minimum_price: selectedVoucher?.minimum_price,
        max_reduce: selectedVoucher?.max_reduce,
        is_limited: selectedVoucher?.is_limited && true,
        payment_method: selectedVoucher?.payment_method
    };
    const isVoucherDataEmpty = selectedVoucher && Object.keys(selectedVoucher).length > 0;
    const isDetail = isVoucherDataEmpty && !isEdit;
    const {
        handleSubmit,
        control,
        formState: {errors},
        watch
    } = useForm({defaultValues});
    const watchAllFields = watch();
    const isFormValid = Object.values(watchAllFields).every((value) => {
        if (value && typeof value === 'object') {
            return new Date(value).toISOString().length > 0;
        }
        return value?.length > 0;
    });

    const addVoucherInputs = [
        {
            label: 'Mã khuyến mãi',
            placeholder: 'Nhập mã khuyến mãi',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'code'
        },
        {
            label: 'Mô tả',
            placeholder: 'Nhập mô tả',
            isRequired: true,
            rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
            type: 'text',
            name: 'description'
        },
        {
            label: 'Phần trăm giảm',
            placeholder: 'Nhập phần trăm sẽ giảm',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'sale_percentage'
        },
        {
            label: 'Số tiền tối thiểu yêu cầu',
            placeholder: 'Nhập loại mã khuyến mãi',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'minimum_price'
        },
        {
            label: 'Số tiền giảm tối đa',
            placeholder: 'Chọn hiệu lực',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'number',
            name: 'max_reduce'
        },
        {
            label: (
                <span>
                    Thời gian hiệu lực <span className='text-required'>*</span>
                </span>
            ),
            type: 'group',
            control,
            groupInputData: [
                {
                    placeholder: 'Từ',
                    isRequired: true,
                    rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
                    type: 'date',
                    name: 'valid_from'
                },
                {
                    placeholder: 'Đến',
                    isRequired: true,
                    rules: {required: {value: true, messages: 'trường này là bắt buộc'}},
                    type: 'date',
                    name: 'valid_to'
                }
            ]
        },
        {
            label: 'Mã có giới hạn',
            placeholder: 'Chọn hiệu lực',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'dropDown',
            name: 'is_limited',
            options: [
                {
                    value: true,
                    label: 'Có'
                },
                {
                    value: false,
                    label: 'Không'
                }
            ]
        },

        {
            label: 'Phương thức thanh toán',
            placeholder: 'Chọn hiệu lực',
            isRequired: false,
            rules: {required: {value: false, messages: 'trường này là bắt buộc'}},
            type: 'dropDown',
            name: 'payment_method',
            options: [
                {
                    value: 'Zalo',
                    label: 'Zalo'
                }
            ]
        }
    ];

    return (
        <CustomCard title='Thông tin mã khuyến mãi'>
            <form
                className='mt-2 add-program'
                method='POST'
                action='#'
                noValidate
                onSubmit={handleSubmit(handleSubmitVoucher, handleSubmitInvalid)}>
                {addVoucherInputs.map((input, idx) => {
                    const {type, rules, groupInputData, label, name, options, ...restProps} = input;
                    return (
                        <FormControl key={idx}>
                            {type !== 'group' ? (
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={rules}
                                    key={idx}
                                    render={({field}) =>
                                        type === 'date' ? (
                                            <CustomDatePicker
                                                {...field}
                                                {...restProps}
                                                label={label}
                                                showTime={false}
                                            />
                                        ) : type === 'dropDown' ? (
                                            <div>
                                                <span>{label}</span>
                                                <Select
                                                    name={name}
                                                    value={field.value}
                                                    defaultValue={'Chọn'}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                    onChange={field.onChange}
                                                    options={options}
                                                    disabled={isDetail}
                                                />
                                            </div>
                                        ) : (
                                            <CustomInput
                                                label={label}
                                                {...field}
                                                {...restProps}
                                                type={type}
                                                disabled={isDetail}
                                            />
                                        )
                                    }
                                />
                            ) : (
                                <GroupInput control={control} label={label} key={idx} groupInputData={groupInputData} disabled={isDetail} showTime={false} />
                            )}
                        </FormControl>
                    );
                })}
                <div className='w-100 d-flex align-items-center justify-content-end gap-3 form-buttons'>
                    <CustomButton
                        variant='outlined'
                        onClick={() => navigateTo(history, dispatch, pagePath.voucherUrl)}
                        type='button'>
                        Trở lại
                    </CustomButton>
                    <CustomButton variant='contained' type='submit'>
                        Lưu
                    </CustomButton>
                </div>
            </form>
        </CustomCard>
    );
};

export default AddVoucher;
