import axiosClient from './axiosClient';

const dashboardApi = {
    getNumberOfOrderByMonth: (date) => {
        const url = `/order/getNumberOfOrderByMonth/${date}`;
        return axiosClient.get(url);
    },
    getIncomeByMonth: (date) => {
        const url = `/order/getIncomeByMonth/${date}`;
        return axiosClient.get(url);
    }
};
export default dashboardApi;
