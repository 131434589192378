import {useEffect} from 'react';
import propsProvider from './dashboardPropsProvider';
import DashboardMainView from './template/DashboardMainView';
import {axisClasses} from '@mui/x-charts';
import {getWindowDimensions} from 'utils/hooks/useDeviceSize';
import {getIncomeByMonth, getNumberOfOrderByMonth} from './dashboardSlice';
import dayjs from 'dayjs';
import {numberToVND} from 'utils/numberToVND';
// import { axisClasses } from '@mui/x-charts/ChartsAxis';
const DashboardContainer = (props) => {
    const {dashboard, dispatch} = props;
    const {orderDashboard, inComeDashboard} = dashboard;

    
    useEffect(() => {
        fetchDashBoard();
    }, [dispatch]);

    const fetchDashBoard = async () => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const currentDay = new Date().getDate();
        const currentDate = currentYear + '-' + currentMonth + '-' + currentDay;
        await dispatch(getNumberOfOrderByMonth(currentDate)).unwrap();
        await dispatch(getIncomeByMonth(currentDate)).unwrap();
    };
    const sizeWindow = getWindowDimensions();

    const chartSetting = {
        yAxis: [
            {
                
            }
        ],
        width: sizeWindow.width / 1.4,
        height: sizeWindow.height / 2,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)'
            }
        }
    };
    const valueFormatter = (value) => `${value}`;
    const valueFormatterIncome = (value) => `${value}`;

    function daysInMonth(month, year) {
        const daysInMonths = [
            31,
            year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28,
            31,
            30,
            31,
            30,
            31,
            31,
            30,
            31,
            30,
            31
        ];
        return daysInMonths[month - 1];
    }

    const handleGetCountOrderPerDay = () => {
        let returnData = [];
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        orderDashboard?.forEach((order) => {
            returnData.push({
                order: parseInt(order.count),
                day: dayjs(order.date).format('D')
            });
        });

        for (let index = 0; index < daysInMonth(currentMonth, currentYear); index++) {
            if (!returnData.find((item) => item.day === `${index + 1}`))
                returnData.push({
                    order: 0,
                    day: `${index + 1}`
                });
        }
        returnData.sort((a, b) => a.day - b.day);
        return returnData;
    };

    const handleGetIncomePerDay = () => {
        let returnData = [];
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        inComeDashboard?.forEach((income) => {
            returnData.push({
                order: parseInt(income.total),
                day: dayjs(income.date).format('D')
            });
        });

        for (let index = 0; index < daysInMonth(currentMonth, currentYear); index++) {
            if (!returnData.find((item) => item.day === `${index + 1}`))
                returnData.push({
                    order: 0,
                    day: `${index + 1}`
                });
        }
        returnData.sort((a, b) => a.day - b.day);
        return returnData;
    };

    const datasetOrder = handleGetCountOrderPerDay();
    const datasetIncome = handleGetIncomePerDay();

    const dashboardContainerProps = {
        ...props,
        chartSetting,
        valueFormatter,
        valueFormatterIncome,
        datasetOrder,
        datasetIncome,
        sizeWindow
    };

    return <DashboardMainView {...propsProvider(dashboardContainerProps)} />;
};

export default DashboardContainer;
