import React from 'react';
import AuthContainer from './AuthContainer';
import {useDispatch, useSelector} from 'react-redux';
import {authSelector} from 'app/selectors';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

const Auth = (props) => {
    const auth = useSelector(authSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const {isLogin, userLoginInfo} = auth;
    const authProps = {
        dispatch,
        isLogin,
        userLoginInfo,
        history,
        ...props
    };
    return <AuthContainer {...authProps} />;
};

export default Auth;
