import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';
import orderApi from 'api/orderApi';
const initialState = () => {
    if (sessionStorage.getItem(Config.storageKey.orderList)) {
        return {
            ...JSON.parse(sessionStorage.getItem(Config.storageKey.orderList))
        };
    }
    return {
        orderList: [],
        paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1})
    };
};

export const getListOrder = createAsyncThunk('order/getListOrder', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getListOrder,
        payload: data
    });
});

export const getOrderByID = createAsyncThunk('order/getOrderByID', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getOrderById,
        payload: data
    });
});

export const updateOrder = createAsyncThunk('order/updateOrder', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.updateOrder,
        payload: data
    });
});

export const updateOrderStatus = createAsyncThunk('order/updateOrderStatus', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.updateOrderStatus,
        payload: data
    });
});

export const deleteOrder = createAsyncThunk('order/deleteOrder', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.deleteOrder,
        payload: data
    });
});

const order = createSlice({
    name: 'order',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const {activePage, page} = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const {activePage, pageSize} = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        },
        handleChangeTotalPage: (state, action) => {
            const {activePage, totalPage = 1} = action.payload;
            state.paginationData[activePage].totalPage = totalPage;
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getListOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orderList = action?.payload?.responseData?.rows;
            sessionStorage.setItem(Config.storageKey.orderList, JSON.stringify(state));
        });
    }
});

const {reducer, actions} = order;
export const {handleChangePage, handleChangePageSize, handleChangeTotalPage} = actions;
export default reducer;
