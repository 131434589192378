import React from 'react';
import HeaderContainer from './HeaderContainer';

const Header = (props) => {
	const headerProps = {
		...props
	};

	return (
		<HeaderContainer {...headerProps} />
	);
};

export default Header;