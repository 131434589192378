import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {numberToVND} from 'utils/numberToVND';
export default function propsProvider(props) {
    const {
        searchProductsValue,
        setSearchProductsValue,
        handleSearchProduct,
        dispatch,
        history,
        productList,
        setPage,
        activePage,
        setPageSize,
        products,
        handleDeleteSelectedRows,
        handleSelectRow,
        handleSelectAllClick,
        checkedData,
        detailImageList,
        handleDeleteImageList,
        handleUploadImageList,
        handleAddProduct,
        handleSubmitInvalid,
        handleProductDetail,
        selectedProduct,
        handleDeleteProduct,
        handleDeleteImageApi,
        handleUpdateProduct,
        modalContent
    } = props;
    const {paginationData} = products;
    const {page, pageSize, totalPage} = paginationData[activePage] || {};

    const productTableData =
        productList &&
        productList.map((el) => ({
            id: el?.id,
            name: {value: el?.name, isshow: true},
            product_count: {value: el?.product_count, isshow: true},
            quantity_sold: {value: el?.quantity_sold, isshow: true},
            original_price: {value: numberToVND(el?.original_price), isshow: true},
            promotion_price: {value: el?.promotion_price === -1 ? '0' : numberToVND(el?.promotion_price), isshow: true},
            actions: {
                value: (
                    <div className='d-flex justify-content-center gap-3'>
                        <VisibilityIcon className='cursor-pointer' onClick={() => handleProductDetail(el?.id, false)} />
                        <EditIcon className='cursor-pointer' onClick={() => handleProductDetail(el?.id, true)} />
                        <CancelIcon className='cursor-pointer' onClick={() => handleDeleteProduct(el)} />
                    </div>
                ),
                isshow: true
            },
            productObj: {
                ...el
            }
        }));
    const productHeadCells = [
        {
            id: 'Tên sản phẩm',
            align: 'left',
            padding: 'none',
            label: 'Tên sản phẩm'
        },
        {
            id: 'Sản lượng',
            align: 'left',
            padding: 'normal',
            label: 'Sản lượng'
        },
        {
            id: 'Đã bán',
            align: 'left',
            padding: 'normal',
            label: 'Sản lượng đã bán'
        },
        {
            id: 'Giá gốc',
            align: 'left',
            padding: 'normal',
            label: 'Giá gốc'
        },
        {
            id: 'Giá khuyến mãi',
            align: 'left',
            padding: 'normal',
            label: 'Giá khuyến mãi'
        },
        {
            id: 'Thao tác',
            align: 'center',
            padding: 'normal',
            label: 'Thao tác'
        }
    ];

    const addProductProps = {
        dispatch,
        history,
        detailImageList,
        handleDeleteImageList,
        handleUploadImageList,
        handleAddProduct,
        handleSubmitInvalid,
        handleDeleteImageApi,
        handleUpdateProduct
    };

    const productDetailProps = {
        selectedProduct,
        dispatch,
        history
    };

    return {
        ...props,
        searchProductsValue,
        setSearchProductsValue,
        handleSearchProduct,
        dispatch,
        history,
        headCells: productHeadCells,
        tableData: productTableData,
        setPage,
        page,
        pageSize,
        totalPage,
        setPageSize,
        handleDeleteSelectedRows,
        handleSelectRow,
        handleSelectAllClick,
        checkedData,
        addProductProps,
        productDetailProps,
        modalContent
    };
}
