export const homeSelector = (state) => state.home;
export const programSelector = (state) => state.program;
export const newsSelector = (state) => state.news;
export const supplierSelector = (state) => state.supplier;
export const productsSelector = (state) => state.products;
export const voucherSelector = (state) => state.voucher;
export const orderSelector = (state) => state.order;
export const dashSelector = (state) => state.dashboard;

export const authSelector = (state) => state.auth;
export const loaderSelector = (state) => state.loader;
