import {MenuItem, Select} from '@mui/material';
import React from 'react';
import {formatQuantity} from 'utils/formatQuantity';

const CustomPageSize = ({label, pageSizeRange = [], pageSize = '', setPageSize, id = '', className = ''}) => {
    return (
        <div className={`custom-pageSize ${className}`}>
            <label htmlFor={id}>{label}</label>
            <Select name={id} id={id} value={pageSize} onChange={(e) => setPageSize(e.target.value)} size='small'>
                {pageSizeRange.map((page_size) => (
                    <MenuItem value={page_size} key={page_size}>
                        {formatQuantity(page_size)}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default CustomPageSize;
