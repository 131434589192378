import {authBottom, authFrame, authTopLeft, hospitalLogo, meuShopBackground, meuShopLogo} from 'assets';
import React from 'react';

const AuthLayout = (props) => {
    const {children} = props;

    return (
        <div className='auth'>
            <main className='row m-0 h-100'>
                <div className='col-6 p-0 h-100 d-flex justify-content-center'>
                    <div className='auth-content'>
                        <img src={meuShopLogo} alt='' style={{width: '20%'}} />
                        <section className='auth-form'>{children}</section>
                    </div>
                </div>
                <div className='auth-layout--right'>
                    <img src={meuShopBackground} alt='' />
                </div>
            </main>
        </div>
    );
};

export default AuthLayout;
